export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = config.public.apiBaseUrl

  const api = $fetch.create({
    baseURL: apiBaseUrl,
    headers: {
      accept: 'application/json',
    },
    onRequest({ options }) {
      const token = useCookie('token').value

      if (token) {
        const headers = options.headers ||= {}
        if (Array.isArray(headers)) {
          headers.push(['Authorization', `Bearer ${token}`])
        } else if (headers instanceof Headers) {
          headers.set('Authorization', `Bearer ${token}`)
        } else {
          headers.Authorization = `Bearer ${token}`
        }
      }
    },
    async onResponseError({ response }) {
      if (response.status === 401) {
        // 未ログインの場合はCookie内のtokenを削除して、ログインページにリダイレクト
        useCookie('token').value = null
        await nuxtApp.runWithContext(() => navigateTo('/login'))
      }
    },
  })

  // Expose to useNuxtApp().$api
  return {
    provide: { api },
  }
})
