import { defineStore } from 'pinia'
import type { Tenant } from '~/types/tenant'

export const useTenantStore = defineStore('tenant', {
  state: (): { tenant: Tenant | null } => ({
    tenant: null,
  }),
  actions: {
    async fetchTenant() {
      // ユーザー情報を取得する処理
      const token = useCookie('token').value
      if (token) {
        try {
          const { data } = await useAPI<Tenant>('/tenant')
          this.setTenant(data.value)
        } catch (error) {
          console.error('Failed to fetch user:', error)
          this.setTenant(null)
        }
      } else {
        this.setTenant(null)
      }
    },
    setTenant(tenant: Tenant | null) {
      this.tenant = tenant
    },
    clearTenant() {
      this.tenant = null
    },
  },
  getters: {
    getTenant: state => state.tenant,
    isAuthenticated: (state): boolean => !!state.tenant,
  },
})
