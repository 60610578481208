import {
  faAngleLeft as freeFasFaAngleLeft,
  faAngleRight as freeFasFaAngleRight,
  faAngleUp as freeFasFaAngleUp,
  faAngleDown as freeFasFaAngleDown,
  faArrowRight as freeFasFaArrowRight,
  faArrowLeft as freeFasFaArrowLeft,
  faCheck as freeFasFaCheck,
  faCircleQuestion as freeFasFaCircleQuestion,
  faDoorOpen as freeFasFaDoorOpen,
  faEnvelope as freeFasFaEnvelope,
  faLock as freeFasFaLock,
  faPaperPlane as freeFasFaPaperPlane,
  faUser as freeFasFaUser
} from '@fortawesome/free-solid-svg-icons'

export default {freeFasFaAngleLeft, freeFasFaAngleRight, freeFasFaAngleUp, freeFasFaAngleDown, freeFasFaArrowRight, freeFasFaArrowLeft, freeFasFaCheck, freeFasFaCircleQuestion, freeFasFaDoorOpen, freeFasFaEnvelope, freeFasFaLock, freeFasFaPaperPlane, freeFasFaUser}