import { useAuth } from '~/composables/useAuth'

export default defineNuxtRouteMiddleware(async (to) => {
  const { fetchTenant, isAuthenticated } = useAuth()

  // トークンがあるか確認し、ユーザー情報を取得
  await fetchTenant()

  const tenant = useTenantStore().tenant

  if (
    tenant
    && (!tenant.birthday || !tenant.tel)
    && to.path !== '/register'
  ) {
    // ログイン済みでプロフィールが未設定の場合は/mypage/profileにリダイレクト
    return navigateTo('/register')
  }

  // 認証されていない場合は/loginにリダイレクト
  if (!isAuthenticated.value && to.path !== '/login') {
    return navigateTo('/login')
  }
})
