import { useTenantStore } from '~/stores/tenantStore'
import { useCookie } from '#app'
import type { Tenant } from '~/types/tenant'

export function useAuth() {
  const tokenCookie = useCookie('token')
  const tenantStore = useTenantStore()
  const { $api } = useNuxtApp()

  const login = async (email: string, password: string) => {
    // 外部APIからトークンを取得
    const { token } = await $api<{ token: string }>('/api/v1/tenant/login', {
      method: 'POST',
      body: { email, password },
    })

    // トークンをCookieに保存
    tokenCookie.value = token

    // ユーザー情報を取得して保存
    await fetchTenant()

    // ログイン成功後のリダイレクト処理
    const to = useRoute().redirectedFrom?.path || '/top'
    useRouter().push(to)
  }

  const logout = async () => {
    // Cookieをクリアしてトークンを削除
    tokenCookie.value = null
    tenantStore.clearTenant()
    // ログアウト後のリダイレクト処理
    navigateTo('/login')
  }

  const fetchTenant = async () => {
    if (!tokenCookie.value) return
    if (!tenantStore.tenant) {
      try {
        // ユーザー情報を取得
        const tenant = await $api<Tenant>('/api/v1/tenant/profile', {
          headers: { Authorization: `Bearer ${tokenCookie.value}` },
        })

        // ユーザー情報をストアに保存
        tenantStore.setTenant(tenant)
      } catch (error) {
        // エラーハンドリング
        console.error('Failed to fetch user:', error)
      }
    }
  }

  return {
    login,
    logout,
    fetchTenant,
    isAuthenticated: computed(() => !!tokenCookie.value && !!tenantStore.tenant),
  }
}
