import { default as contactHaqCHDmXWBMeta } from "/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/contact.vue?macro=true";
import { default as faqTyxoY94sy5Meta } from "/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/faq.vue?macro=true";
import { default as login3idbDeG1f7Meta } from "/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/login.vue?macro=true";
import { default as indexHMmOZYha85Meta } from "/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/mypage/index.vue?macro=true";
import { default as lockDCnIh4XejtMeta } from "/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/mypage/lock.vue?macro=true";
import { default as _91month_931hXKh6joPyMeta } from "/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/mypage/payments/[year]/[month].vue?macro=true";
import { default as index3bBXjxJVozMeta } from "/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/mypage/payments/index.vue?macro=true";
import { default as profileaGXNK6XIEpMeta } from "/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/mypage/profile.vue?macro=true";
import { default as _91id_93CX8ErlE1PsMeta } from "/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/mypage/rents/[id].vue?macro=true";
import { default as register3jcEihSY7DMeta } from "/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/register.vue?macro=true";
import { default as completeQiTOft52B6Meta } from "/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/rents/[rent_uuid]/available_date_applications/[available_date_application_uuid]/complete.vue?macro=true";
import { default as confirmJtI01SLEdMMeta } from "/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/rents/[rent_uuid]/available_date_applications/confirm.vue?macro=true";
import { default as sitemapc4RRWuUAvvMeta } from "/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/sitemap.vue?macro=true";
import { default as topB819SqUbwUMeta } from "/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/top.vue?macro=true";
export default [
  {
    name: "contact",
    path: "/contact",
    component: () => import("/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login3idbDeG1f7Meta || {},
    component: () => import("/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "mypage",
    path: "/mypage",
    component: () => import("/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/mypage/index.vue").then(m => m.default || m)
  },
  {
    name: "mypage-lock",
    path: "/mypage/lock",
    component: () => import("/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/mypage/lock.vue").then(m => m.default || m)
  },
  {
    name: "mypage-payments-year-month",
    path: "/mypage/payments/:year()/:month()",
    component: () => import("/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/mypage/payments/[year]/[month].vue").then(m => m.default || m)
  },
  {
    name: "mypage-payments",
    path: "/mypage/payments",
    component: () => import("/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/mypage/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "mypage-profile",
    path: "/mypage/profile",
    component: () => import("/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/mypage/profile.vue").then(m => m.default || m)
  },
  {
    name: "mypage-rents-id",
    path: "/mypage/rents/:id()",
    component: () => import("/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/mypage/rents/[id].vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "rents-rent_uuid-available_date_applications-available_date_application_uuid-complete",
    path: "/rents/:rent_uuid()/available_date_applications/:available_date_application_uuid()/complete",
    component: () => import("/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/rents/[rent_uuid]/available_date_applications/[available_date_application_uuid]/complete.vue").then(m => m.default || m)
  },
  {
    name: "rents-rent_uuid-available_date_applications-confirm",
    path: "/rents/:rent_uuid()/available_date_applications/confirm",
    component: () => import("/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/rents/[rent_uuid]/available_date_applications/confirm.vue").then(m => m.default || m)
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "top",
    path: "/top",
    component: () => import("/codebuild/output/src2290941798/src/hoteresi-nuxt/pages/top.vue").then(m => m.default || m)
  }
]